import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import 'amfe-flexible'
import './assets/common.less'
import { Button, Badge, Icon, ActionSheet, Toast } from 'vant';
Vue.use(Button);
Vue.use(Badge);
Vue.use(Icon);
Vue.use(ActionSheet);
Vue.use(Toast);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
